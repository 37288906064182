<script>
  import {onMount} from 'svelte';
  export let message;

  onMount(async () =>{
    message = "svelte test";
  });
</script>

<div class="duo-test">
  <p>{message}</p>
</div>